import React, { useEffect, useState } from 'react';
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import "./Ebook.css";
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Button } from 'antd';
import CountUp from 'react-countup';
import { Col, Row, Statistic, Tabs } from 'antd';
import { LikeOutlined } from '@ant-design/icons';
import { PiBookOpen, PiBookOpenDuotone, PiEyeBold } from 'react-icons/pi';
import { FaAllergies, FaBook, FaBookOpen, FaGlobe, FaListUl } from 'react-icons/fa';
import { MdBookOnline } from 'react-icons/md';
import { Input, Space } from 'antd';
const { Search } = Input;
const onSearch = (value, _e, info) => console.log(info?.source, value);


const formatter = (value) => <CountUp end={value} separator="," />;

const Card = ({ imgSrc, title, description, ebookUrl }) => {
  const navigate = useNavigate();
  const handleClick = () => {

    // Navigate to the "ebook-playread" page and pass the state data
    navigate('/ebook-playread', { state: { imgSrc, title, description, ebookUrl } });
  };

  return (
    <div className={`card hover `}>
      <div className="card-img" style={{ backgroundImage: `url(${imgSrc})` }}>
        <div className="overlay">
          <div className="overlay-content">
            <Button className="hover" onClick={handleClick}>
              Mulai Membaca
            </Button>
          </div>
        </div>
      </div>

      <div className="card-content">
        <div>
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

const Ebook = () => {
  const generateDummyData = () => {
    const dummyData = [];
    for (let i = 1; i <= 20; i++) {
      dummyData.push({
        imgSrc: `https://upload.wikimedia.org/wikipedia/id/5/5c/Minhajulqashidin.png`,
        title: `Ihya Ulumuddin`,
        description: `Lorem ipsum dolor sit amet consectetur, lorem ipsum dolor ${i}`,
        ebookUrl: 'https://online.fliphtml5.com/mrume/conm/'
      });
    }
    return dummyData;
  };

  const [dummyData, setDummyData] = useState(generateDummyData);

  useEffect(() => {
    const cardTimeout = setTimeout(() => {
      const cards = document.querySelectorAll('.card');
      const hoverLinks = document.querySelectorAll('a.hover');

      cards.forEach(card => card.classList.remove('hover'));
      hoverLinks.forEach(link => link.classList.remove('hover'));
    }, 1800);

    // Clear the timeout if the component unmounts or the effect re-runs
    return () => clearTimeout(cardTimeout);
  }, []); // The empty dependency array ensures that the effect runs only once after the initial render

  return (
    <div>
      <>
        <Header />
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>E-Library</h2>
                <h6>Pondok Pesantren Modern Tahfidzul Qur'an Nuridin Idris </h6>
              </div>
            </div>
          </div>
        </section>

     
        <section className="meetings-page bg-white" id="meetings">
          <div className='d-flex justify-content-center'>
        <h1>Dalam Tahap Pengembangan</h1>
          </div>
          {/* <div className='container'>

            <div className='d-flex justify-content-between align-items-center ps-2 pe-3 mb-3'>



              <Statistic title="Total Buku" value={1128} prefix={<PiBookOpen />} formatter={formatter} />


            </div>

            <div className='d-flex justify-content-center align-items-center ps-2 pe-3 mb-5'>
              <Search
                style={{ width: "100%" }}
                placeholder="Cari Judul Buku"
                allowClear
                enterButton="Search"
                size="large"
                onSearch={onSearch}
              />
            </div>
            <div>
              <Tabs
                defaultActiveKey="1"
                centered
                tabPosition="left"
                items={[
                  {
                    label: 'Semua',
                    key: '1',
                    icon: <FaListUl />,
                    children: dummyData.map((data, index) => (
                      <Card
                        key={index}
                        imgSrc={data.imgSrc}
                        title={data.title}
                        description={data.description}
                      />
                    )),
                  },
                  {
                    label: 'Kitab Kuning',
                    key: '2',
                    icon: <FaBook />,
                    children: dummyData.filter(data => data.title === 'Ihya Ulumuddin').map((data, index) => (
                      <Card
                        key={index}
                        imgSrc={data.imgSrc}
                        title={data.title}
                        description={data.description}
                      />
                    )),
                  },
                  {
                    label: 'Umum',
                    key: '3',
                    icon: <FaGlobe />,
                    children: dummyData.filter(data => data.title !== 'Ihya Ulumuddin').map((data, index) => (
                      <Card
                        key={index}
                        imgSrc={data.imgSrc}
                        title={data.title}
                        description={data.description}
                      />
                    )),
                  },
                ]}
              />
            </div>
          </div>
          <Footer /> */}
        </section>
      </>
    </div>
  );
}

export default Ebook;
