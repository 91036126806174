import React, { useEffect, useState } from 'react';
import "../../css/lightbox.css";
import "../../css/owl.css";
import "../../css/global.css";
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import BaseUrl from '../../apiConfig';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



const BeritaTerkini = () => {

  const [meetingsData, setmeetingsData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${BaseUrl}api/information/latest`);
        setmeetingsData(response.data.data)

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const moveDetail = (beritaDetail) => {
    navigate("/detail-berita", {
      state: {
        data: beritaDetail

      },
    });
  };



  return (
    <div>
      <>
        {/* ***** Header Area Start ***** */}
        <Header />
        {/* ***** Header Area End ***** */}
        <section className="heading-page header-text" id="top">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2>Berita Terkini</h2>
                <h6>List berita terbaru </h6>
              </div>
            </div>
          </div>
        </section>
        <section className="meetings-page" id="meetings">
          <div className="container">
            <div className="row">
              <div className="col-lg-2">
                <div className="categories bg-light p-3" style={{ borderRadius: "8px" }}>
                  <h6 className='pb-3 mb-3' style={{ borderBottom: "1px solid #EEEEEE" }}>Arsip Berita</h6>
                  <ul>
                    <li className='pb-1 ' style={{ color: "#8A9A60", textDecoration: "underline" }}>
                      <a href="#">2024</a>
                    </li>
                    <li className='pb-1 '>
                      <a href="#">2025</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-10">
                <section>
                  <div className="container">
                    <div className="row gx-3 align-items-center justify-content-between">
                      <div className="col-md-12 order-2 order-md-1">
                        <div className="row">
                          {meetingsData.map((meeting, index) => (
                            <div className="col-lg-4" key={index}>
                                <div className="meeting-item" onClick={() => moveDetail(meeting)} style={{ cursor: "pointer" }}>
                                  <div className="thumb">
                                    <img src={meeting.image_url} alt={meeting.title}
                                      style={{ width: '100%', height: "200px", cursor: "pointer" }} />
                                  </div>
                                  <div className="down-content">
                                    <div className="date">
                                      <h6>
                                        {new Date(meeting?.date).toLocaleDateString('en-GB', {
                                          day: '2-digit',
                                          month: 'short',
                                        })}
                                      </h6>
                                    </div>
                                    <Link>
                                      <h4>{meeting.title}</h4>
                                    </Link>
                                  </div>
                                </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <Footer />
        </section>
      </>
    </div>
  );
};

export default BeritaTerkini;
