import React from 'react'
import "../../css/lightbox.css"
import "../../css/owl.css"
import "../../css/global.css"
import { Link } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { useLocation } from 'react-router-dom';
import { Image } from 'antd';

const DetailBerita = () => {

    const location = useLocation();
    const { data: course } = location.state || {};

    console.log(location.state)

    const paragraphs = course.description.split('\r\n\r\n');

    return (
        <div>
            <>
                {/* ***** Header Area Start ***** */}
                <Header />
                <section className="heading-page header-text" id="top">
                    {/* div */}
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                                <h6>Detail Berita</h6>
                                <h2>{course.title}</h2>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="meetings-page" id="meetings">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="meeting-single-item">

                                            <div className="down-content">
                                                <a href="meeting-details.html">
                                                    <h4>{course.title}</h4>
                                                </a>
                                                <p>
                                                    Author : {course.author}
                                                </p>
                                                <p className="description">
                                                    {course.description}
                                                </p>
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="hours">
                                                            <h5>Waktu</h5>
                                                            <p>
                                                                <p>{new Date(course.date).toLocaleString('en-GB', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-4">
                                                        <Image
                                                            src={course.image_url}
                                                            alt={course.title}
                                                            style={{ width: '100%' }}
                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="main-button-red">
                                            <Link to={-1}>Kembali</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    <Footer />
                </section>
            </>
        </div>
    )
}

export default DetailBerita